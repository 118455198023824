import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout'
import HomePageContent from 'components/frontpage'

const FrontPageTemplate = ({ data, ...props }) => (
	<Layout
		articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
		articleClassName={`${data.wordpressPage.classes}`}
		context={props.pageContext}
		location={props.location}
	>
		<HomePageContent {...data.wordpressPage} embracingNatureBackground={data.embracingBackground.childImageSharp} />

	</Layout>
);


export default FrontPageTemplate
export const FrontPageQuery = graphql`
query FrontPageQuery{
  embracingBackground:file(relativePath: {eq: "euroseeds/cardBackground_rotated.jpg"}) {
    childImageSharp {
      fluid (maxWidth: 3000){
        ...GatsbyImageSharpFluid
      }
    }
  }
  wordpressPage(path:{eq:"/"}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
    carousel {
        title
        content
        button {
          title
          url
          target
        }
        bg_image{
          localFile{
            childImageSharp{
              fluid(maxWidth:3840){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    acf {
      embracing_nature {
        title
        content
        video
        button {
          title
          url
        }
      }
    }

    discover_section {
      title
      items{
        title
        description
        url
        background_image {
          localFile {
            childImageSharp {
              fixed(height: 252, width: 334) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        color
      }
    }


    double_collaboration_widget {
      title
      project_right {
        title
        excerpt
        path
        featured_media {
          localFile {
            childImageSharp {
              fixed (height: 485, width: 505){
                ...GatsbyImageSharpFixed
              }
              fluid (maxWidth: 768){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      project_left {
        title
        excerpt
        path
        featured_media {
          localFile {
            childImageSharp {
              fixed (height: 485, width: 505){
                ...GatsbyImageSharpFixed
              }
              fluid (maxWidth: 768){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      button {
        title
        url
      }
    }
    didYouKnowSection {
      title
      facts {
        featured_media {
          localFile {
            childImageSharp {
              fixed (height: 521, width: 574){
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        content
        acf {
          link {
            url
            title
            target
          }
        }
      }
    }
  }
}
`
