import React from "react"
import "../style.css"
import PropTypes from "prop-types"
import Header from "./header"
import Footer from "./footer/"
import LayoutStyles from "./layout.module.css"
import { Helmet } from "react-helmet"
import Yoast from "../yoast"
import { Breadcrumbs } from "./breadcrumbs"
const Layout = ({
	children,
	articleId,
	articleClassName,
	ancestors,
	actionsClassName,
	actionsWidgets,
	...props
}) => {
	return (

		<div className={LayoutStyles.wrapper}>
			<Helmet bodyAttributes={{ class: "wp-embed-responsive" }}>
				<script
					id="Cookiebot"
					src="https://consent.cookiebot.com/uc.js"
					data-cbid="ac21ccee-1871-4feb-8f7f-2b3e6d11a5d0"
					type="text/javascript"
					async
				/>
				<link rel="stylesheet" type="text/css" href="/wpstyles.css" />
				<link rel="stylesheet" type="text/css" href="/core_wpstyles.min.css" />
			</Helmet>
			{props.context && props.context.yoast_meta && (
				<Yoast yoast={props.context.yoast_meta} location={props.location} />
			)}
			<Header className={LayoutStyles.header} />
			<section id="primary" className="content-area">
				<main id="main" className="site-main">
					<div className={` ${actionsClassName ? actionsClassName : ''} `}>
						<div className="container flex justify-between pt-4">
							{ancestors && <Breadcrumbs ancestors={ancestors} />}
							{!!actionsWidgets && <>{actionsWidgets}</>}
						</div>
					</div>

					<article id={articleId} className={articleClassName}>
						{children}
					</article>
				</main>
			</section>
			<Footer />
		</div >
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	articleId: PropTypes.string,
	articleClassName: PropTypes.string,
	actionsClassName: PropTypes.string,
	actionsWidgets: PropTypes.any
}

export default Layout
