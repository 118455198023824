import React from 'react'
import { Link } from "../link"
import styles from "./pageToggle.module.css"

const Pill = ({ label, href, className }) => {
	return (<Link to={href} className={`${styles.pill}`} activeClassName={`${styles.activePill}`} >
		{label}
	</Link>)
}
export const PageToggle = ({ pages }) => {
	if (pages?.length < 2) {
		return (<></>)
	}

	return (<div className={`flex gap-0 items-center border border-aubergine text-black ${styles.toggle}`}>
		{
			pages?.map((page, idx) => <Pill
				key={`page-toggle-${idx}`}
				{...page} />
			)}
	</div>
	)
}
