
import React from 'react'
import OrganisationCard from "./card"
import cardStyles from './card.module.css'
import orgStyle from "./organigram.module.css"
const OrganisationSection = ({ title, style: { color_scheme, is_full_width }, members }) => {

	//group cards by background color
	const _members = []
	let last_bg;
	let last_grp = {
		background: null,
		is_full_width: false,
		members: []
	}
	members?.map(m => m.organisation_member_card).forEach(member => {
		if (member.background === last_grp.background && member.full_width === last_grp.is_full_width) {
			//same group, add member
			last_grp.members.push(member)
		} else {
			// new group, commit old group, start new one
			if (last_grp.members?.length) {
				_members.push({ ...last_grp })
			}
			last_grp = { is_full_width: member.full_width, background: member.background, members: [member] }
		}

	})
	if (last_grp.members?.length) {
		_members.push({ ...last_grp })
	}
	return (<section
		className={`${orgStyle.section} ${orgStyle[color_scheme]} ${is_full_width ? "min-w-full" : ''}`}
	>
		<div style={{ height: "65px" }}>
			<h2 className={`${orgStyle.sectionTitle}`} dangerouslySetInnerHTML={{ __html: title }} />
		</div>
		<div className='flex flex-wrap justify-center gap-4'>
			{_members.map((grp, idx) =>


				<div
					key={`organisation-grp-{idx}`}
					className={`${grp.is_full_width ? 'flex w-full justify-center' : ''}`}
				>
					<div
						key={`organisation-grp-{idx}`}
						className={`h-full ${cardStyles.cardGroup} ${!!cardStyles[grp.background] ? cardStyles[grp.background] : ''} `}
					>
						{grp.members?.length > 0 && grp.members.map((member, midx) => (
							<OrganisationCard
								key={`organisation - card - ${idx} - ${midx}`}
								{...member} />
						))}
					</div>
				</div>
			)}
		</div>
	</section >)
}
export default OrganisationSection
