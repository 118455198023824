
import React from 'react'
import OrganisationSection from "./section"

const Organigram = ({ sections }) => {
	return (<div className='flex flex-wrap gap-y-16 gap-x-4 justify-center'>
		{sections.map((section, idx) => {
			return (
				<OrganisationSection
					{...section} key={`organisation-section-${idx}`} />
			)
		})}
	</div>)
}
export default Organigram
