
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { PageToggle } from '.';

const TeamModeToggle = (props) => {
	const { data } = useStaticQuery(graphql`
    query TeamModeToggleOptionsQuery {
      data:wordpressAcfOptions{
      options{
team_toggle {
href: page
label
}
    }
}
}

    `)
	let pages = data?.options?.team_toggle ?? []
	return (
		<>
			{pages.length > 0 && <PageToggle pages={pages} />}
		</>
	)

}
export default TeamModeToggle
