import React from 'react'
import cardStyles from "./card.module.css"

import Img from 'gatsby-image/withIEPolyfill';
const OrganisationCard = ({ name, picture, department, roles, background }) => {
	const _roles = roles?.split(";")?.filter(r => !(r === ""))
	return (
		<div className={`${cardStyles.card}`}>
			<div className={`${cardStyles.pic}`}>
				{picture?.localFile?.childImageSharp?.fixed && <Img fixed={picture.localFile.childImageSharp.fixed} height={85} width={85} style={{ borderRadius: "8px" }} />}

			</div>
			{_roles?.length > 0 && (<div>
				{_roles.map((role, idx) => <span className={`${cardStyles.role}`} key={idx} dangerouslySetInnerHTML={{ __html: role }} />)}
			</div>)}
			{department && (<div><span className={`${cardStyles.dpt}`} dangerouslySetInnerHTML={{ __html: department }} /></div>)}
			<span dangerouslySetInnerHTML={{ __html: name }} />
		</div>
	)
}
export default OrganisationCard
